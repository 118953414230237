<template>
  <main>
    <Row :gutter="15">
      <Col :span="17">
        <ButtonGroup shape="circle">
          <Button icon="md-refresh" @click="loadData">刷新</Button>
          <Button icon="md-add-circle" @click="create" v-if="Auth([ 'News', 'create' ])">添加</Button>
        </ButtonGroup>
      </Col>
      <Col :span="7">
        <searcher ref="searcher" :options="searches" @on-change="loadData"></searcher>
      </Col>
    </Row>
    <Divider/>
    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider/>
    <pagination ref="pagination" :total="total" @on-change="loadData"/>
  </main>
</template>

<script>
export default {
  data() {
    return {
      searches: {
        title: "标题"
      },
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "标题",
          key: "title",
          sortable: "custom",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h("div", { class: "flex flex-align-center" }, [
              h("img", {
                attrs: {
                  src: params.row.cover ? params.row.cover : ""
                },
                class: "cover mr"
              }),
              h("div", [h("p", params.row.title)])
            ]);
          }
        },
        {
          title: "日期",
          minWidth: 140,
          key: "date",
          align: "center",
          sortable: "custom"
        },
        {
          title: "阅读量",
          key: "records_count",
          minWidth: 120,
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h("span", params.row.records_count);
          }
        },
        {
          title: "排序",
          minWidth: 90,
          key: "weight",
          align: "center",
          sortable: "custom",
          render: (h, params) => {
            var html = [h("span", params.row.weight)];

            if (params.row.stick) {
              html.push(
                h("Icon", {
                  props: {
                    type: "md-arrow-round-up"
                  },
                  class: "text-primary ml-xs"
                })
              );
            }

            return h("div", html);
          }
        },
        {
          title: "状态",
          minWidth: 90,
          key: "visible",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h("Icon", {
              props: {
                type: params.row.visible
                  ? "ios-checkmark-circle"
                  : "ios-remove-circle"
              },
              class: params.row.visible ? "text-green" : "text-red"
            });
          }
        },

        {
          title: "创建时间",
          minWidth: 140,
          key: "created_at",
          align: "center",
          sortable: "custom"
        },
        {
          title: "操作",
          minWidth: 110,
          align: "center",
          render: (h, params) => {
            var html = [];

            if (this.Auth(["News", "update"])) {
              html.push(
                h("Button", {
                  props: {
                    type: "success",
                    size: "small",
                    icon: "md-create"
                  },
                  class: "mg-lr-xs",
                  on: {
                    click: () => {
                      this.update(params.row);
                    }
                  }
                })
              );
            }

            if (this.Auth(["News", "delete"])) {
              html.push(
                h("Button", {
                  props: {
                    type: "error",
                    size: "small",
                    icon: "md-trash"
                  },
                  class: "mg-lr-xs",
                  on: {
                    click: () => {
                      this.remove(params.row);
                    }
                  }
                })
              );
            }

            return h("div", html);
          }
        }
      ]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http.get("/news/list", { params: params }).then(res => {
        this.total = res.total;
        this.data = res.data;
      });
    },
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    create() {
      this.$router.push("/news/create");
    },
    update(item) {
      this.$router.push("/news/update/" + item.id);
    },
    remove(item) {
      this.$Modal.confirm({
        title: "确定要删除该资讯吗？",
        content: item.title,
        onOk: () => {
          this.$http.post("/news/delete/id/" + item.id).then(res => {
            this.loadData();
          });
        }
      });
    }
  }
};
</script>